//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ElCheckbox from '@/components/UI/el-checkbox.vue'
import { ru } from 'vuejs-datepicker/dist/locale'
import { isEmail } from '@/utils/validator/validateRules'
import eventBus from '@/utils/eventBus'
import { addEmailToSubscribers } from '@/utils/subscription/subscriber'
import { mapState } from 'vuex'
import { newMasks, flags } from '~/const/masks.js'

const { getCookie } = require('@/utils/cookie')

export default {
  name: 'ApplicationFormSection',
  components: {
    ElCheckbox,
  },
  events: ['form-submit'],
  props: {
    crmTags: {
      type: String,
      required: true,
    },
    crmPipeline: {
      type: String,
      required: true,
    },
    crmStatus: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: () => 'Send',
    },
    thanksModalName: {
      type: String,
      required: false,
      default: 'modal-thanks',
    },
    errorModalName: {
      type: String,
      required: false,
      default: 'modal-error',
    },
    isRedesignForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniqueOnPageFormName: {
      type: String,
      required: false,
      default: () => 'generalForm',
      validator(value) {
        return ['generalForm', 'facultyStartSection'].includes(value)
      },
    },
    isDetailv2: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    headerText: {
      type: String,
      default: '',
    },
    pText: {
      type: String,
      default: '',
    },
    newsletterText: {
      type: String,
      default: '',
    },
    privacyPolicyText: {
      type: String,
      default: '',
    },
    isEmailText: {
      type: Boolean,
      default: true,
    },
    validationTexts: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isEmail,
      branchCode: this.$getTextMainSetting('branchCode'),
      submitButtonDisabled: false,
      phone: '',
      email: '',
      gradeSchool: '',
      name: '',
      privacy: false,
      customField: '',
      ageInterval: '',
      additionalFormFieldTitle: this.$getTextMainSetting(
        'additional_form_field'
      ),
      birthDate: '',
      format: 'dd.MM.yyyy',
      course: 'COURSE AMO ID', // TODO remove
      ru,
      errors: {
        name: false,
        phone: false,
        email: false,
        privacy: false,
      },
      focusedInput: '',
      newsSubscription: false,
      masksList: newMasks || [],
      countryCode: 'ru',
      countryPrefix: '+7',
      isCountrySelectorActive: false,
    }
  },
  computed: {
    ...mapState(['i18n']),
    isEmailRequired() {
      return this.$getTextMainSetting('email_required')
    },
    showAdditionalFormField() {
      return this.$getTextMainSetting('show_additional_form_field')
    },
    confirmMailingFeedText() {
      return (
        this.$getTextMainSetting('confirmMailingFeedText') ||
        this.$t(
          'components.common.applicationFormSection.confirmMailingFeedText'
        )
      )
    },
    confirmPersonalInformationAgreementText() {
      return (
        this.$getTextMainSetting('confirmPersonalInformationAgreementText') ||
        this.$t(
          'components.common.applicationFormSection.personalInformationAgreement'
        )
      )
    },
    confirmPersonalInformationAgreementLink() {
      return (
        this.$getTextMainSetting('confirmPersonalInformationAgreementLink') ||
        this.$t(
          'components.common.applicationFormSection.personalInformationAgreementLink'
        )
      )
    },
    confirmPrivacyPolicyAgreementText() {
      return (
        this.$getTextMainSetting('confirmPrivacyPolicyAgreementText') ||
        this.$t(
          'components.common.applicationFormSection.privacyPolicyAgreement'
        )
      )
    },
    confirmPrivacyPolicyAgreementLink() {
      return (
        this.$getTextMainSetting('confirmPrivacyPolicyAgreementLink') ||
        this.$t(
          'components.common.applicationFormSection.privacyPolicyAgreementLink'
        )
      )
    },
  },
  mounted() {
    if (this.$getTextSetting('branchCountryCode')) {
      this.countryCode = this.$getTextSetting('branchCountryCode').toLowerCase()
    }
    this.phone = this.getCountryPrefix(this.countryCode)

    window.addEventListener(
      'click',
      (e) => {
        if (
          !e.target.closest('.custom-phone-input__prefix') &&
          this.isCountrySelectorActive
        ) {
          this.isCountrySelectorActive = false
        }
      },
      { passive: true }
    )

    this.$recaptcha.init()
  },
  methods: {
    onClickPhone() {
      if (this.$nuxt) {
        this.$nuxt.$on(this.uniqueOnPageFormName, (e) => {
          this.countryCode = e
        })
      }
    },
    onInputBlur() {
      this.focusedInput = ''
      this.errors.phone = !this.isPhoneComplete(this.phone)
    },
    getCookie(name) {
      const cookie = document.cookie.match(
        '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)'
      )
      return cookie ? cookie.pop() : ''
    },
    getHost() {
      const currentDomainData = this.$getCurrentDomainData()
      return currentDomainData.siteUrl
    },
    getUrlParameter(name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      const results = regex.exec(location.search)
      return results === null
        ? '(none)'
        : decodeURIComponent(results[1].replace(/\+/g, ' '))
    },
    normalizePhone(phone) {
      phone = phone.replace(/^(\+7|8)/, '+7') // russian phone
      phone = phone.replace(/[ |()-]/g, '')
      return phone
    },
    isName(name) {
      const regex = /^([а-яА-Яa-zA-ZёЁa-žA-Ž ()-]{2,50})+$/
      return regex.test(name)
    },
    isPhoneComplete(phone) {
      // Incomplete phone number has placeholder symbols
      phone = phone.replace(/[ |()\-+]/g, '')

      if (phone.startsWith('789')) {
        return false
      }

      return /^\d{5,}$/.test(phone) // must contain only digits, mask sets length restriction
    },
    onSendButtonClick() {
      if (!this.formIsValid()) {
        return
      }
      if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-undef
        if (ym !== undefined) {
          // eslint-disable-next-line no-undef
          ym(this.yaMetricId, 'reachGoal', 'send_button_click')
        }
      }
    },
    getCountryFlag(code) {
      if (flags[code]) {
        return flags[code]
      } else {
        return flags.ru
      }
    },
    getCountryPrefix(code) {
      if (newMasks[code.toLowerCase()]) {
        return newMasks[code.toLowerCase()].c
      }

      return newMasks.ru.c
    },
    changeCountryMask(code) {
      this.countryCode = code
      this.phone = this.countryPrefix = newMasks[code].c
      this.$refs.phoneInput.focus()
    },
    toggleCountrySelector() {
      this.isCountrySelectorActive = !this.isCountrySelectorActive
    },
    async onSubmit() {
      if (this.formIsValid()) {
        const reCaptchaToken = await this.$recaptcha.execute('lead_create')

        this.submitButtonDisabled = true
        try {
          const requestData = this.dataBuild(reCaptchaToken)
          const { data } = await this.$axios.post(
            '/api/lead/create',
            requestData
          )
          if (data.data !== undefined && data.data.error !== undefined) {
            throw new Error(
              'Error create lead: ' +
                JSON.stringify(requestData) +
                '; Message: ' +
                data.data.message +
                '; Error code: ' +
                data.data.error
            )
          }
          this.showSuccessMessage({
            email: requestData.email,
            name: requestData.name,
          })
          this.formClear()
          await this.submitPostprocessing(requestData)
          if (this.isDetailv2) {
            this.$emit('openGoodHandleSubmit')
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Caught Error:', error)
          this.$sentry.captureException(error)
          this.showErrorMessage()
        }
      } else {
        this.$sentry.captureException(
          new Error(
            'formInvalid: ' +
              this.branchCode +
              ', ' +
              this.phone +
              ', ' +
              this.email +
              ', ' +
              this.name
          )
        )
      }
      this.submitButtonDisabled = false
    },
    async submitPostprocessing(data) {
      try {
        this.sendGtmEvent()
        data.branchId = this.$getTextMainSetting('branch_id')
        await this.sendPostback(data)
        if (
          this.newsSubscription &&
          !this.$getTextMainSetting('isWorldFranchise')
        ) {
          await this.subscribeUser(data.email)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        this.$sentry.captureException(error)
      }
    },
    async subscribeUser(email) {
      const currentDomainData = this.$getCurrentDomainData()
      await addEmailToSubscribers(email, currentDomainData)
    },
    sendPostback(data) {
      this.$axios.post('/api/postback', data)
    },
    showSuccessMessage(data) {
      if (this.isRedesignForm) {
        this.$emit('form-submit', data)
      } else {
        this.$modal.hide('modal-signup-courses')
        this.$emit('form-submitted')
        this.$modal.show(this.$props.thanksModalName)
      }
    },
    showErrorMessage() {
      if (this.isRedesignForm) {
        this.$emit('form-submit-error')
      }
    },
    showFailMessage() {
      this.$modal.show('modal-fail-create-lead')
    },
    formIsValid() {
      const name = this.$refs['input-name']
      const phone = this.$refs.phoneInput
      const email = this.$refs['input-email']
      Object.keys(this.errors).forEach((v) => (this.errors[v] = false))
      if (!this.isName(this.name)) {
        this.errors.name = true
        name.setCustomValidity(this.validationTexts.name)
      } else {
        this.errors.name = false
        name.setCustomValidity('')
      }
      if (!this.isPhoneComplete(this.phone)) {
        this.errors.phone = true
        phone.setCustomValidity(this.validationTexts.phone)
      } else {
        this.errors.phone = false
        phone.setCustomValidity('')
      }
      if (
        this.isEmailText &&
        !this.isEmail(this.email) &&
        this.isEmailRequired
      ) {
        this.errors.email = true
        email.setCustomValidity(this.validationTexts.email)
      } else {
        this.errors.email = false
        email.setCustomValidity('')
      }
      this.phone = this.normalizePhone(this.phone)
      // отрефакторить // первое if для footer второе для pop-up позвони мне
      if (!this.privacy) {
        if (this.isDetailv2 && this.privacyPolicyText === '') {
          this.errors.privacy = false
        } else if (this.isDetailv2 && !this.isEmailText) {
          this.errors.privacy = false
        } else {
          this.errors.privacy = true
        }
      }
      return (
        !this.errors.name &&
        !this.errors.phone &&
        !this.errors.email &&
        !this.errors.privacy
      )
    },
    dataBuild(reCaptchaToken) {
      return {
        branch: this.branchCode,
        phone: this.phone,
        email: this.email,
        privacy: this.privacy,
        email_user: {
          sent: true,
        },
        name: this.name,
        custom_field: this.customField,
        landing_url: window.location.href,
        birth_date: this.birthDate,
        cpa_param: getCookie('cpa_param'),
        cpa_value: getCookie('cpa_value'),
        cpa_initial_query: getCookie('cpa_initial_query'),
        amo_lead: {
          // либо pipelineId если есть, либо pipeline будет использовано при создании лида в монолите
          pipelineId: this.$getTextSetting('crm_pipeline_id'),
          pipeline: this.crmPipeline,
          // либо statusId если есть, либо status будет использовано при создании лида в монолите
          statusId: this.$getTextSetting('crm_pipeline_status_id'),
          status: this.crmStatus,
          tags: this.appendAdditionalCrmTags(this.crmTags),
          course: this.course, // TODO COURSE '<?= $amoId; ?>',
          send: true,
          fields: {
            grade_school: this.gradeSchool,
            birth_date: this.birthDate,
            landing_url: window.location.href,
          },
        },
        _meta: this.getMeta(),
        re_captcha_token: reCaptchaToken,
        cookies: {
          advcake_track_id: getCookie('advcake_track_id'),
          advcake_track_url: getCookie('advcake_track_url'),
        },
      }
    },
    sendGtmEvent() {
      eventBus.$emit('send-form-submit')
    },
    formClear() {
      this.name = ''
      this.email = ''
      this.phone = this.getCountryPrefix(this.countryCode)
      this.customField = ''
    },
    getMeta() {
      return this.$getSourceBusterMeta()
    },
    appendAdditionalCrmTags(crmTags) {
      let tags = crmTags
      const landingCustomTags = this.$getTextSetting('crm_custom_tags')
      const branchId = this.$getTextMainSetting('branch_id')

      const additionalTags = new Map([
        [2333, 'Березники'],
        [2669, 'Соликамск'],
        [376, 'Митино'],
        [2594, 'Красногорск'],
      ])

      if (additionalTags.has(branchId)) {
        if (crmTags.length > 0) {
          tags = `${crmTags},${additionalTags.get(branchId)}`
        } else {
          tags = additionalTags.get(branchId)
        }
      }

      if (landingCustomTags) {
        const tmpArr = landingCustomTags.split(',')
        if (tmpArr.length > 0) {
          tags = `${tags},${landingCustomTags}`
        }
      }

      return tags
    },
  },
}
